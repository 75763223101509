.App {
  text-align: center;
  padding: 20px 0px 0px 0px;
}

.EventList {
  margin: 20px 0px 0px 0px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .App {
    text-align: center;
    padding: 0px 0px 0px 0px;
  }
}

.ListNavigation {
  width: 100%;
  background-color: slategrey;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
}

.App-link {
  color: #61dafb;
}

.flexbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
